const Content = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="135" height="78" viewBox="0 0 135 78" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M128.6 11C131.692 11 134.2 13.5072 134.2 16.6C134.2 19.6928 131.692 22.2 128.6 22.2H96.5996C99.6924 22.2 102.2 24.7072 102.2 27.8C102.2 30.8928 99.6924 33.4 96.5996 33.4H114.2C117.292 33.4 119.8 35.9072 119.8 39C119.8 42.0928 117.292 44.6 114.2 44.6H106.06C102.161 44.6 98.9996 47.1072 98.9996 50.2C98.9996 52.2619 100.6 54.1285 103.8 55.8C106.892 55.8 109.4 58.3072 109.4 61.4C109.4 64.4928 106.892 67 103.8 67H37.3996C34.3068 67 31.7996 64.4928 31.7996 61.4C31.7996 58.3072 34.3068 55.8 37.3996 55.8H6.19961C3.10681 55.8 0.599609 53.2928 0.599609 50.2C0.599609 47.1072 3.10681 44.6 6.19961 44.6H38.1996C41.2924 44.6 43.7996 42.0928 43.7996 39C43.7996 35.9072 41.2924 33.4 38.1996 33.4H18.1996C15.1068 33.4 12.5996 30.8928 12.5996 27.8C12.5996 24.7072 15.1068 22.2 18.1996 22.2H50.1996C47.1068 22.2 44.5996 19.6928 44.5996 16.6C44.5996 13.5072 47.1068 11 50.1996 11H128.6ZM128.6 33.4C131.692 33.4 134.2 35.9072 134.2 39C134.2 42.0928 131.692 44.6 128.6 44.6C125.507 44.6 123 42.0928 123 39C123 35.9072 125.507 33.4 128.6 33.4Z"
            fill="#232A34"
        />
        <path
            d="M37.8496 4.6001C37.8496 3.08131 39.0808 1.8501 40.5996 1.8501H81.4085C82.1411 1.8501 82.8434 2.14243 83.3597 2.66225L95.3508 14.7361C95.8625 15.2512 96.1496 15.9478 96.1496 16.6739V73.4001C96.1496 74.9189 94.9184 76.1501 93.3996 76.1501H40.5996C39.0808 76.1501 37.8496 74.9189 37.8496 73.4001V4.6001Z"
            fill="#181A1C"
            stroke="#8D95A2"
            strokeWidth="2.5"
        />
        <path
            d="M82.1992 3V13.1694C82.1992 14.6222 83.3785 15.8 84.8333 15.8H91.7992"
            stroke="#8D95A2"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M47 62.2003H70.7023M47 15.8003H70.7023H47ZM47 26.7179H86.2H47ZM47 38.5454H86.2H47ZM47 50.3728H86.2H47Z"
            stroke="#8D95A2"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default Content;
