import { useState, useEffect, useCallback, useMemo } from 'react';

import { ChevronLeft, ChevronRight, Cross, Delete, Download } from '@dronebase/shared-ui-icons';
import styled from '@emotion/styled';
import {
    Overlay,
    ActionIcon,
    Flex,
    Group,
    Text,
    Box,
    Stack,
    UnstyledButton,
    UnstyledButtonProps,
    Center,
    Button,
    Portal,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useAtom, useAtomValue } from 'jotai';

import Content from 'components/common/Icons/Content';
import { IMAGE_MIME_TYPE, IMAGE_PREVIEW_DETAILS_SIDEBAR_WIDTH } from 'lib/constants';
import { getCookie } from 'lib/cookies';
import { formatDateWithLocalTimeShort } from 'lib/helpers';
import useDownloadImage from 'lib/hooks/useDownloadImage';
import { useGpsFromImage } from 'lib/hooks/useGPSFromImage';
import { BlobInstance, HandleInitiateDelete } from 'lib/types/inspections/anomalies';
import { fileToDeleteAtom, fullscreenFileAtom } from 'state/inspections/anomalySidebar';

import { SingleDetail } from '../ModuleDetailsPanel/SingleDetail';

const FilePreview = ({
    hasFileManagerPermission = false,
    portalContainerRef,
    onDelete,
}: {
    hasFileManagerPermission: boolean;
    portalContainerRef: React.RefObject<HTMLDivElement>;
    onDelete: HandleInitiateDelete;
}) => {
    const [fullScreenImageBlobInstance, setFullScreenBlobInstanceImage] = useAtom(fullscreenFileAtom);
    const fileToDelete = useAtomValue(fileToDeleteAtom);
    const download = useDownloadImage();
    const [imageDetailsPanelOpened, { toggle: toggleImageDetailsPanel }] = useDisclosure(false);
    const userId = String(getCookie('helio-userId'));

    // If we have a URL, fetch it as a Blob to parse EXIF data
    const [imageBlob, setImageBlob] = useState<Blob | null>(null);

    useEffect(() => {
        const fetchImageAsBlob = async () => {
            const imageUrl = fullScreenImageBlobInstance?.url ?? '';

            if (
                (imageUrl &&
                    fullScreenImageBlobInstance?.blob_index.type === 'unknown' &&
                    fullScreenImageBlobInstance?.blob_index.object_name.includes('.webp')) ||
                IMAGE_MIME_TYPE.includes(fullScreenImageBlobInstance?.blob_index.type ?? '')
            ) {
                const response = await fetch(imageUrl);
                const blob = await response.blob();

                setImageBlob(blob);
            } else {
                setImageBlob(null);
            }
        };

        fetchImageAsBlob();
    }, [fullScreenImageBlobInstance]);

    const { latitude, longitude } = useGpsFromImage(imageBlob);

    const createdAtDate = new Date(fullScreenImageBlobInstance?.blob_index.blob_created_at ?? '');
    const formattedCreatedAtDate = formatDateWithLocalTimeShort(createdAtDate);

    let fileType = fullScreenImageBlobInstance?.blob_index.type;

    if (fileType === 'unknown' && fullScreenImageBlobInstance?.blob_index.object_name.includes('.webp')) {
        fileType = 'image/webp';
    }

    const fileIsImage = IMAGE_MIME_TYPE.includes(fileType ?? '');

    const closePreview = useCallback(() => {
        setFullScreenBlobInstanceImage(null);
    }, [setFullScreenBlobInstanceImage]);

    const userCanDelete = useMemo(
        () =>
            hasFileManagerPermission &&
            fullScreenImageBlobInstance?.blob_index.blob_index_bonds.some((bond) => bond.user_uuid === userId),
        [fullScreenImageBlobInstance, hasFileManagerPermission, userId],
    );

    return (
        <Portal target={portalContainerRef.current as HTMLElement}>
            <Overlay opacity={1} color="var(--color-grey-900)" zIndex={1000} h="100%" w="100%">
                <Flex h="100%" w="100%" style={{ position: 'relative' }}>
                    <Stack h="100%" w="100%" pos="relative" align="center" p="0rem 2rem 8rem 2rem">
                        <Flex top="1rem" right="1rem" w="100%" justify="space-between" align="center" gap="0.5rem">
                            <Group position="center" w="100%">
                                <Text variant="body2light" c="gray.0">
                                    {fullScreenImageBlobInstance?.blob_index.object_name.split('/')[1]}
                                </Text>
                            </Group>
                            <Group noWrap pr="0.5rem" h="4rem">
                                <ActionIcon
                                    variant="light"
                                    onClick={() =>
                                        fullScreenImageBlobInstance?.url && download(fullScreenImageBlobInstance.url)
                                    }
                                >
                                    <Download />
                                </ActionIcon>
                                {userCanDelete && (
                                    <ActionIcon
                                        variant="light"
                                        onClick={() => onDelete(fileToDelete as BlobInstance, false)}
                                    >
                                        <Delete />
                                    </ActionIcon>
                                )}
                                <ActionIcon variant="light" onClick={closePreview}>
                                    <Cross />
                                </ActionIcon>
                            </Group>
                        </Flex>

                        <Center h="100%" w="100%">
                            {fileIsImage ? (
                                <StyledImage src={fullScreenImageBlobInstance?.url} alt="Preview" />
                            ) : (
                                <Stack spacing="2rem" align="center" justify="center">
                                    <Content />
                                    <Text variant="body1" c="gray.2">
                                        Preview is unavailable
                                    </Text>
                                    <Button
                                        variant="outline"
                                        size="md"
                                        onClick={() => download(fullScreenImageBlobInstance?.url ?? '')}
                                    >
                                        Download File
                                    </Button>
                                </Stack>
                            )}
                        </Center>
                    </Stack>

                    <Box
                        style={{
                            width: imageDetailsPanelOpened ? IMAGE_PREVIEW_DETAILS_SIDEBAR_WIDTH : '0',
                            maxWidth: IMAGE_PREVIEW_DETAILS_SIDEBAR_WIDTH,
                            transition: 'width 0.3s ease-in-out',
                            backgroundColor: 'var(--color-grey-700)',
                            position: 'relative',
                            overflow: 'visible',
                            zIndex: 0,
                            boxShadow: 'inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                        }}
                    >
                        {imageDetailsPanelOpened && (
                            <Stack
                                p="1.5rem"
                                w="100%"
                                maw={IMAGE_PREVIEW_DETAILS_SIDEBAR_WIDTH}
                                h="100%"
                                spacing="1rem"
                                pos="relative"
                            >
                                <Group spacing={0} noWrap position="apart" align="flex-start">
                                    <Text maw="90%" variant="subtitle2" c="gray.0">
                                        {fullScreenImageBlobInstance?.blob_index.object_name.split('/')[1]}
                                    </Text>
                                    <ActionIcon onClick={closePreview} mt="-0.9rem" mr="-1rem">
                                        <Cross />
                                    </ActionIcon>
                                </Group>
                                <SingleDetail label="Creation Date" value={formattedCreatedAtDate} />
                                <SingleDetail
                                    label="Uploaded By"
                                    value={fullScreenImageBlobInstance?.blob_index.blob_index_bonds[0].user.email}
                                />
                                <SingleDetail
                                    label="GPS Coordinates"
                                    value={
                                        latitude && longitude
                                            ? `${latitude.toFixed(5)}, ${longitude.toFixed(5)}`
                                            : 'Not Available'
                                    }
                                />
                                <SingleDetail
                                    label="Caption"
                                    value={fullScreenImageBlobInstance?.caption ?? 'No caption'}
                                />
                            </Stack>
                        )}

                        <SidebarToggleButton onClick={toggleImageDetailsPanel}>
                            {imageDetailsPanelOpened ? (
                                <ChevronRight color="var(--color-white)" />
                            ) : (
                                <ChevronLeft color="var(--color-white)" />
                            )}
                        </SidebarToggleButton>
                    </Box>
                </Flex>
            </Overlay>
        </Portal>
    );
};

export default FilePreview;

const SidebarToggleButton = styled(UnstyledButton)<UnstyledButtonProps & { onClick:() => void }>({
    height: '3.25rem',
    width: '1.375rem',
    borderRadius: '0.25rem 0 0 0.25rem',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    position: 'absolute',
    left: '-1.375rem',
    top: '50%',
    zIndex: 4000,
    backgroundColor: 'var(--color-purple-400)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const StyledImage = styled.img`
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    display: block;
`;
