import { useState } from 'react';

interface UseShowMoreOptions {
    defaultShownLimit: number;
}

export const useShowMore = ({ defaultShownLimit }: UseShowMoreOptions) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggle = () => setIsExpanded((prev) => !prev);

    const getLimitedItems = <T>(items: T[]): T[] => (isExpanded ? items : items.slice(0, defaultShownLimit));

    const buttonText = isExpanded ? 'Show Less' : 'Show More';

    return { toggle, getLimitedItems, buttonText, isExpanded };
};
