import { Loader } from '@dronebase/shared-ui-core';
import { Box, Group, Text } from '@mantine/core';
import { useRouteLoaderData } from 'react-router-dom';

import { AnomalyEntry, InspectionLoaderData } from 'components/inspections/types';
import useAnomalyFiles from 'lib/hooks/useAnomalyFiles';

import AnomalyThumbnail from './AnomalyThumbnail';
import LegacyAnomalyImages from './LegacyAnomalyImages';

const AnomalyImages = ({ anomaly }: { anomaly: AnomalyEntry }) => {
    const { reportType } = useRouteLoaderData('inspection') as InspectionLoaderData;
    const { internalFiles, fetching: fetchingInternalImages } = useAnomalyFiles(anomaly.uuid);

    const { legacyAnomaly } = anomaly.aoi;

    const hasAnomalyImages =
        reportType === 'Glass Crack Inspection' ||
        legacyAnomaly?.irSampleImages?.length > 0 ||
        legacyAnomaly?.vizSampleImages?.length > 0 ||
        !legacyAnomaly?.irSampleImages?.every((image) => !image?.url) ||
        !legacyAnomaly?.vizSampleImages?.every((image) => !image?.url);

    return (
        <Group>
            <LegacyAnomalyImages anomaly={anomaly} showSampleImages />
            {fetchingInternalImages ? (
                <Box p="lg">
                    <Loader />
                </Box>
            ) : (
                internalFiles.map((imageData, index) => (
                    <AnomalyThumbnail
                        key={index}
                        src={imageData.url}
                        alt={`GlassCrack-${anomaly.uuid}`}
                        isSampleImage={false}
                    />
                ))
            )}
            {!hasAnomalyImages && (
                <Text variant="body2light" c="gray.0">
                    -
                </Text>
            )}
        </Group>
    );
};

export default AnomalyImages;
