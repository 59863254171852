import { useCallback, useMemo } from 'react';

import { Loader } from '@dronebase/shared-ui-core';
import styled from '@emotion/styled';
import { Box, Button, ButtonProps, Stack } from '@mantine/core';
import { useSetAtom } from 'jotai';

import { ANOMALY_FILE_DEFAULT_SHOWN_LIMIT } from 'lib/constants';
import { getCookie } from 'lib/cookies';
import { UploadState } from 'lib/hooks/useGCSUpload';
import { useShowMore } from 'lib/hooks/useShowMore';
import useWorkspaceDetails from 'lib/hooks/useWorkspaceDetails';
import { BlobInstance, HandleInitiateDelete } from 'lib/types/inspections/anomalies';
import { fileToDeleteAtom, fullscreenFileAtom } from 'state/inspections/anomalySidebar';

import AnomalyFileCard from './AnomalyTab/AnomalyFileCard';

export const UploadedFilesListContainer = ({
    files,
    fetchingFiles,
    onDelete,
    uploadState,
}: {
    files: BlobInstance[];
    fetchingFiles: boolean;
    onDelete: HandleInitiateDelete;
    uploadState: UploadState;
}) => {
    const { toggle, getLimitedItems, buttonText } = useShowMore({
        defaultShownLimit: ANOMALY_FILE_DEFAULT_SHOWN_LIMIT,
    });

    const setFullScreenFile = useSetAtom(fullscreenFileAtom);
    const setFileToDelete = useSetAtom(fileToDeleteAtom);
    const { currentUserHasFileManagerPermission } = useWorkspaceDetails();
    const userId = String(getCookie('helio-userId'));

    const onRowClick = useCallback(
        (file: BlobInstance) => {
            setFullScreenFile(file);
            setFileToDelete(file);
        },
        [setFullScreenFile, setFileToDelete],
    );

    const displayedFiles = useMemo(() => getLimitedItems(files), [files, getLimitedItems]);

    const userUploadedFileCards = useMemo(
        () =>
            displayedFiles.map((file, index) => {
                const fileName = file.blob_index.object_name.split('/').pop() as string;
                const dynamicIndex = (file.blob_index.object_name.split('/').pop() as string) + index;

                const userCanDelete =
                    currentUserHasFileManagerPermission &&
                    file.blob_index.blob_index_bonds.some((bond) => bond.user_uuid === userId);

                return (
                    <AnomalyFileCard
                        key={dynamicIndex}
                        file={file}
                        fileName={fileName}
                        onRowClick={() => onRowClick(file)}
                        onDelete={() => onDelete(file)}
                        isDeleteEnabled={userCanDelete}
                    />
                );
            }),
        [displayedFiles, currentUserHasFileManagerPermission, userId, onRowClick, onDelete],
    );

    return (
        <Stack>
            {fetchingFiles ? (
                <Box p="lg" my="2rem">
                    <Loader />
                </Box>
            ) : (
                <Stack spacing="0.25rem" pt="0.25rem" pos="relative" w="100%">
                    {uploadState.isUploading && (
                        <AnomalyFileCard uploadState={uploadState} caption={uploadState.caption} />
                    )}
                    {userUploadedFileCards}
                    {files.length > ANOMALY_FILE_DEFAULT_SHOWN_LIMIT && (
                        <ShowMoreButton size="md" onClick={toggle} variant="text">
                            {buttonText}
                        </ShowMoreButton>
                    )}
                </Stack>
            )}
        </Stack>
    );
};

const ShowMoreButton = styled(Button)<ButtonProps & { onClick:() => void }>({
    ':hover': {
        color: 'var(--color-purple-400)',
    },
    'minHeight': 'var(--btn-min-h-md)',
    'minWidth': 'var(--btn-min-w-md)',
    'fontSize': 'var(--btn-font-size-md)',
    'width': 'min-content',
    'color': 'var(--color-purple-300)',
    'textAlign': 'center',
    'fontFamily': 'Roboto',
    'fontStyle': 'normal',
    'fontWeight': 400,
    'lineHeight': '150%',
});
