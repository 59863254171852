import { useCallback, useEffect } from 'react';

import { ConfirmModal, notifications } from '@dronebase/shared-ui-core';
import { Cross, Opened } from '@dronebase/shared-ui-icons';
import { Stack, Text, Button, Group, Image, ActionIcon, Box, Textarea } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { useForm, Controller } from 'react-hook-form';

import {
    FILE_UPLOAD_ERROR_NOTIFICATION,
    MAX_FILE_SIZE,
    MAX_FILE_SIZE_MB,
    MAX_FILENAME_LENGTH_UPLOAD_MODAL,
    MAX_UPLOAD_FILE_COUNT,
} from 'lib/constants';
import { truncateFileNameWithExtension } from 'lib/helpers';
import { Metadata } from 'lib/hooks/useGCSUpload';
import { getAnomalyFileUploadUrl } from 'lib/mutations/getAnomalyFileUploadUrl';
import { LightThemeProvider } from 'providers/LightThemeProvider';

interface UploadFileModalProps {
    opened: boolean;
    onClose: () => void;
    siteId: string | undefined;
    anomalyUUID: string;
    superReportTaskUUID: string | undefined;
    uploadToGCS: (file: File, metadata: Metadata) => Promise<{ ok: boolean }>;
    refetchFiles: () => void;
    finalizeUpload: () => void;
}

export interface FormValues {
    caption: string;
    file: File;
}

const UploadFileModal = ({
    opened,
    onClose,
    siteId,
    anomalyUUID,
    superReportTaskUUID,
    uploadToGCS,
    refetchFiles,
    finalizeUpload,
}: UploadFileModalProps) => {
    const {
        control,
        handleSubmit,
        reset,
        setError,
        resetField,
        watch,
        formState: { errors },
    } = useForm<FormValues>();

    useEffect(() => {
        if (opened) {
            reset();
        }
    }, [opened, reset]);

    const onSubmit = async (formData: FormValues) => {
        try {
            onClose();
            const { data: metadata, error } = await getAnomalyFileUploadUrl(
                formData,
                siteId,
                anomalyUUID,
                superReportTaskUUID,
            );

            if (error || !metadata) {
                notifications.error(FILE_UPLOAD_ERROR_NOTIFICATION);

                return;
            }

            const uploadResponse = await uploadToGCS(formData.file, metadata);

            if (uploadResponse?.ok) {
                notifications.success({
                    title: 'Upload Successful',
                    message: 'File uploaded successfully!',
                });
                reset();

                setTimeout(() => {
                    refetchFiles();
                    finalizeUpload();
                }, 1000);
            }
        } catch (error) {
            notifications.error(FILE_UPLOAD_ERROR_NOTIFICATION);
        }
    };

    const handleClose = useCallback(() => {
        onClose();
        reset();
    }, [onClose, reset]);

    const caption = watch('caption');
    const file = watch('file');
    const hasErrors = !caption || !file || !!errors.caption || !!errors.file;
    const maxFileCountErrorMessage = `Only ${MAX_UPLOAD_FILE_COUNT} file can be uploaded at a time.`;

    return (
        <LightThemeProvider>
            <ConfirmModal
                buttonPosition="start"
                confirmLabel="Upload"
                cancelLabel="Cancel"
                modalContentProps={{
                    confirmButtonProps: {
                        disabled: hasErrors,
                    },
                }}
                onConfirm={handleSubmit(onSubmit)}
                opened={opened}
                onClose={handleClose}
                title="Upload File"
                size="sm"
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack>
                        <Controller
                            name="caption"
                            control={control}
                            defaultValue=""
                            render={({ field, fieldState }) => (
                                <Textarea
                                    required
                                    autosize
                                    maxRows={4}
                                    withAsterisk
                                    label="Caption"
                                    placeholder="Enter a caption"
                                    {...field}
                                    error={fieldState.error?.message}
                                    styles={{ input: { overflow: 'auto' } }}
                                />
                            )}
                            rules={{ required: 'Caption is required' }}
                        />

                        <Controller
                            name="file"
                            control={control}
                            render={({ field, fieldState }) => (
                                <>
                                    <Stack spacing="0.25rem">
                                        <Dropzone
                                            disabled={!!field.value}
                                            onDrop={(files) => {
                                                resetField('file');
                                                field.onChange(files[0]);
                                            }}
                                            onReject={(files) => {
                                                if (files.length > 1) {
                                                    setError('file', {
                                                        type: 'manual',
                                                        message: maxFileCountErrorMessage,
                                                    });
                                                } else {
                                                    setError('file', {
                                                        type: 'manual',
                                                        message: `File size must not exceed ${MAX_FILE_SIZE_MB} MB`,
                                                    });
                                                }

                                                field.onChange(null);
                                            }}
                                            maxSize={MAX_FILE_SIZE}
                                            maxFiles={MAX_UPLOAD_FILE_COUNT}
                                            style={{ width: '100%', cursor: field.value ? 'not-allowed' : 'pointer' }}
                                            styles={{
                                                root: {
                                                    'border': fieldState.error
                                                        ? '1px dashed #fa5252'
                                                        : '1px dashed var(--color-grey-200)',
                                                    'borderRadius': '0.25rem',
                                                    'backgroundColor': field.value
                                                        ? 'var(--color-grey-25)'
                                                        : 'var(--color-white)',
                                                    ':hover': {
                                                        backgroundColor: field.value
                                                            ? 'var(--color-grey-25)'
                                                            : 'var(--color-white)',
                                                    },
                                                },
                                                inner: {
                                                    pointerEvents: 'all',
                                                },
                                            }}
                                        >
                                            <Stack ta="center" justify="center" align="center" spacing="0.5rem">
                                                <Opened
                                                    color="var(--color-grey-300)"
                                                    width="1.25rem"
                                                    height="1.25rem"
                                                />
                                                <Text variant="body2light" c={field.value ? 'gray.3' : 'gray.9'}>
                                                    Drag & drop a file or{' '}
                                                    <Button
                                                        variant="link"
                                                        size="md"
                                                        c={field.value ? 'gray.3' : undefined}
                                                        styles={{
                                                            root: {
                                                                pointerEvents: field.value ? 'none' : 'all',
                                                            },
                                                        }}
                                                    >
                                                        Browse
                                                    </Button>
                                                </Text>
                                                <Text variant="body3light" c="gray.4">
                                                    (Maximum file size: {MAX_FILE_SIZE_MB} MB)
                                                </Text>
                                            </Stack>
                                        </Dropzone>
                                        {fieldState.error && (
                                            <Text variant="caption1" c="#fa5252">
                                                {fieldState.error.message}
                                            </Text>
                                        )}
                                    </Stack>

                                    {field.value && (
                                        <Group position="apart" align="center" mt="sm" noWrap maw="100%" pos="relative">
                                            <Group w="90%" align="center" noWrap spacing="1rem" pos="relative">
                                                {field.value.type.startsWith('image/') && (
                                                    <Image
                                                        src={URL.createObjectURL(field.value)}
                                                        alt={field.value.name}
                                                        width="2rem"
                                                        height="2rem"
                                                        fit="cover"
                                                        radius="sm"
                                                    />
                                                )}
                                                <Box w="100%" p={0} m={0}>
                                                    <Text w="100%" variant="body2" c="gray.9">
                                                        {truncateFileNameWithExtension(
                                                            field.value.name,
                                                            MAX_FILENAME_LENGTH_UPLOAD_MODAL,
                                                        )}
                                                    </Text>
                                                </Box>
                                            </Group>

                                            <ActionIcon w="auto" size="sm" onClick={() => field.onChange(null)}>
                                                <Cross />
                                            </ActionIcon>
                                        </Group>
                                    )}
                                </>
                            )}
                            rules={{
                                required: 'File is required',
                                validate: (file) =>
                                    file?.size <= MAX_FILE_SIZE || `File size must not exceed ${MAX_FILE_SIZE_MB} MB`,
                            }}
                        />
                    </Stack>
                </form>
            </ConfirmModal>
        </LightThemeProvider>
    );
};

export default UploadFileModal;
