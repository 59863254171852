import { useEffect, useRef, useState } from 'react';

import { Text, TextProps, Tooltip } from '@mantine/core';

const OverflowTooltip = ({
    text,
    color,
    variant,
}: {
    text: string;
    color: TextProps['c'];
    variant: TextProps['variant'];
}) => {
    const textRef = useRef<HTMLDivElement>(null);
    const [isOverflowing, setIsOverflowing] = useState(false);

    useEffect(() => {
        if (textRef.current) {
            const overflowing = textRef.current.scrollWidth > textRef.current.clientWidth;

            setIsOverflowing(overflowing);
        }
    }, [text]);

    return (
        <Tooltip
            label={<Text variant="caption1">{text}</Text>}
            disabled={!isOverflowing}
            transitionProps={{ transition: 'pop', duration: 300 }}
            position="top-start"
            styles={{
                tooltip: {
                    whiteSpace: 'pre-wrap',
                    padding: '0.38rem 0.75rem',
                    backgroundColor: 'var(--color-grey-50)',
                    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.25)',
                },
            }}
        >
            <Text
                ref={textRef}
                variant={variant}
                c={color}
                style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
                maw="min-content"
            >
                {text}
            </Text>
        </Tooltip>
    );
};

export default OverflowTooltip;
