import { ReactNode, useCallback, useEffect, useRef } from 'react';

import styled from '@emotion/styled';
import { Box, BoxProps, Flex, Stack } from '@mantine/core';
import { useSetAtom } from 'jotai';
import { Outlet } from 'react-router-dom';

import { useAnomalyMapData } from 'lib/hooks';
import {
    AnomalyMapSectionsProvider,
    SiteGeometryProvider,
    MapLayersProvider,
    HighlightedAssetsProvider,
    MiniMapLayersProvider,
    DifferentialAnomalyDataProvider,
    MapSignalColorsProvider,
    DifferentialSignalCountProvider,
} from 'lib/hooks/map';
import { DarkThemeProvider } from 'providers/DarkThemeProvider';
import { fullscreenFilePreviewContainerRefAtom } from 'state/inspections/anomalySidebar';

import AnomaliesMap from './AnomaliesMap';
import LoaderOverlay from './LoaderOverlay';
import MapLayerFilters from './MapLayerFilters';

const AnomalyMapPage = () => {
    const { fetchingReportAnomalies } = useAnomalyMapData();
    const containerRef = useRef<HTMLDivElement>(null);
    const setFullscreenFilePreviewContainerRef = useSetAtom(fullscreenFilePreviewContainerRefAtom);

    useEffect(() => {
        if (containerRef.current) {
            setFullscreenFilePreviewContainerRef(containerRef);
        }
    }, [setFullscreenFilePreviewContainerRef]);

    const wrapProviders = useCallback(
        (children: ReactNode) => (
            <MapSignalColorsProvider>
                <AnomalyMapSectionsProvider>
                    <DifferentialAnomalyDataProvider>
                        <DifferentialSignalCountProvider>
                            <SiteGeometryProvider>
                                <HighlightedAssetsProvider>
                                    <MapLayersProvider>
                                        <MiniMapLayersProvider>{children}</MiniMapLayersProvider>
                                    </MapLayersProvider>
                                </HighlightedAssetsProvider>
                            </SiteGeometryProvider>
                        </DifferentialSignalCountProvider>
                    </DifferentialAnomalyDataProvider>
                </AnomalyMapSectionsProvider>
            </MapSignalColorsProvider>
        ),
        [],
    );

    return wrapProviders(
        <DarkThemeProvider>
            <MapContainer ref={containerRef}>
                <MapSection>
                    {fetchingReportAnomalies && <LoaderOverlay />}
                    <MapLayerFilters />
                    <AnomaliesMap />
                </MapSection>
                <SidePanelContainer>
                    <SidePanelContent>
                        <Outlet />
                    </SidePanelContent>
                </SidePanelContainer>
            </MapContainer>
        </DarkThemeProvider>,
    );
};

export default AnomalyMapPage;

export const MAX_SIDE_PANEL_WIDTH = '24rem';

const MapContainer = styled(Flex)({
    overflow: 'hidden',
    flexBasis: '100%',
    position: 'relative', // Needed for Portal Overlay positioning
});

const MapSection = styled(Stack)({
    position: 'relative',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
});

const SidePanelContainer = styled(Box)<BoxProps>({
    maxWidth: MAX_SIDE_PANEL_WIDTH,
    flexBasis: '100%',
    display: 'flex',
    overflow: 'hidden',
});

const SidePanelContent = styled.div({
    overflow: 'auto',
    backgroundColor: 'var(--color-grey-700)',
    flexBasis: '100%',
    display: 'flex',
    flexDirection: 'column',
});
