import { RefObject, useCallback } from 'react';

import { Upload } from '@dronebase/shared-ui-icons';
import { Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useAtom, useAtomValue } from 'jotai';
import { useParams } from 'react-router-dom';

import DeleteModal from 'components/common/modals/DeleteModal';
import { useFeatureFlag } from 'lib/hooks';
import useAnomalyFiles from 'lib/hooks/useAnomalyFiles';
import { useGcsUpload } from 'lib/hooks/useGCSUpload';
import useWorkspaceDetails from 'lib/hooks/useWorkspaceDetails';
import deleteAnomalyFile from 'lib/mutations/deleteAnomalyFile';
import { HandleInitiateDelete } from 'lib/types/inspections/anomalies';
import {
    deleteModalOpenedAtom,
    fileToDeleteAtom,
    fullscreenFileAtom,
    fullscreenFilePreviewContainerRefAtom,
} from 'state/inspections/anomalySidebar';

import FilePreview from '../../FilePreview/FilePreview';
import { SingleDetail } from '../SingleDetail';
import { UploadedFilesListContainer } from '../UploadedFilesListContainer';
import UploadFileModal from '../UploadFileModal';

interface AnomalyFileManagerProps {
    anomalyUUID: string;
}

const AnomalyFileManager = ({ anomalyUUID }: AnomalyFileManagerProps) => {
    const { currentUserHasFileManagerPermission } = useWorkspaceDetails();
    const { siteId, inspectionId } = useParams();
    const isAnomalyFileUploadEnabled = useFeatureFlag('IS_ANOMALY_FILE_UPLOAD_ENABLED');
    const { externalFiles, fetching, refetchFiles } = useAnomalyFiles(anomalyUUID);

    const [fullScreenImageBlobInstance, setFullScreenImageBlobInstance] = useAtom(fullscreenFileAtom);
    const previewPortalContainerRef = useAtomValue(
        fullscreenFilePreviewContainerRefAtom,
    ) as RefObject<HTMLDivElement> | null;

    const [opened, { open, close }] = useDisclosure(false);
    const { uploadToGCS, uploadState, finalizeUpload } = useGcsUpload();

    const [deleteModalOpened, setDeleteModalOpened] = useAtom(deleteModalOpenedAtom);
    const [fileToDelete, setFileToDelete] = useAtom(fileToDeleteAtom);

    const closePreview = useCallback(() => {
        setFullScreenImageBlobInstance(null);
    }, [setFullScreenImageBlobInstance]);

    const handleInitiateDelete: HandleInitiateDelete = (file, shouldClosePreview) => {
        setFileToDelete(file);
        shouldClosePreview && closePreview();
        setDeleteModalOpened(true);
    };

    const handleConfirmDelete = async () => {
        if (fileToDelete) {
            await deleteAnomalyFile(fileToDelete.blob_index.uuid);
            setTimeout(() => undefined, 1000);
            refetchFiles();
        }

        setFileToDelete(null);
        setDeleteModalOpened(false);
        setFullScreenImageBlobInstance(null);
    };

    return (
        isAnomalyFileUploadEnabled && (
            <>
                <DeleteModal
                    opened={deleteModalOpened}
                    onClose={() => {
                        setDeleteModalOpened(false);
                        setFileToDelete(null);
                    }}
                    onDelete={handleConfirmDelete}
                    zIndex={9999}
                />
                <SingleDetail
                    label="Uploaded Files"
                    uploadButton={
                        currentUserHasFileManagerPermission && (
                            <Button
                                onClick={open}
                                leftIcon={<Upload width="1rem" height="1rem" />}
                                variant="text"
                                disabled={uploadState.isUploading}
                                size="md"
                                styles={{
                                    root: {
                                        'color': 'var(--color-purple-300)',
                                        ':hover': {
                                            color: 'var(--color-purple-400)',
                                        },
                                    },
                                }}
                            >
                                Upload
                            </Button>
                        )
                    }
                >
                    <UploadedFilesListContainer
                        files={externalFiles}
                        fetchingFiles={fetching}
                        onDelete={handleInitiateDelete}
                        uploadState={uploadState}
                    />
                    <UploadFileModal
                        opened={opened}
                        onClose={close}
                        siteId={siteId}
                        anomalyUUID={anomalyUUID}
                        superReportTaskUUID={inspectionId}
                        uploadToGCS={uploadToGCS}
                        refetchFiles={refetchFiles}
                        finalizeUpload={finalizeUpload}
                    />
                    {fullScreenImageBlobInstance &&
                        previewPortalContainerRef?.current &&
                        isAnomalyFileUploadEnabled && (
                            <FilePreview
                                hasFileManagerPermission={currentUserHasFileManagerPermission}
                                portalContainerRef={previewPortalContainerRef}
                                onDelete={handleInitiateDelete}
                            />
                        )}
                </SingleDetail>
            </>
        )
    );
};

export default AnomalyFileManager;
