import { ReactNode } from 'react';

import { Group, Stack, Text } from '@mantine/core';

export const SingleDetail = ({
    label,
    value,
    children,
    uploadButton,
}: {
    label: string;
    value?: string | undefined | null;
    children?: ReactNode | null;
    uploadButton?: ReactNode | null;
}) => {
    const formattedValue = value ?? '-';

    return (
        <Stack spacing="0.125rem">
            <Group position="apart">
                <Text variant="caption1" c="gray.3">
                    {label}
                </Text>
                {uploadButton && uploadButton}
            </Group>
            {children ?? (
                <Text
                    variant="body2light"
                    c="gray.0"
                    style={{
                        whiteSpace: 'pre-wrap', // Allow line breaks
                    }}
                >
                    {formattedValue}
                </Text>
            )}
        </Stack>
    );
};
