import { RefObject } from 'react';

import { atom } from 'jotai';

import { BlobInstance } from 'lib/types/inspections/anomalies';

export const fullscreenFileAtom = atom<BlobInstance | null>(null);
export const fileToDeleteAtom = atom<BlobInstance | null>(null);
export const fullscreenFilePreviewContainerRefAtom = atom<RefObject<HTMLDivElement> | null>(null);
export const deleteModalOpenedAtom = atom(false);
