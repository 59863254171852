import { useMemo } from 'react';

import { useParams } from 'react-router-dom';
import { CombinedError, useQuery, UseQueryExecute } from 'urql';

import { AnomalyEntry } from 'components/inspections/types';
import { GET_ANOMALY_IMAGES_AND_FILES } from 'lib/queries';
import { BlobInstance } from 'lib/types/inspections/anomalies';

const useAnomalyFiles = (
    anomalyUUID: AnomalyEntry['uuid'],
): {
    internalFiles: BlobInstance[];
    externalFiles: BlobInstance[];
    fetching: boolean;
    error: CombinedError | undefined;
    refetchFiles: UseQueryExecute;
} => {
    const { siteId, inspectionId } = useParams();

    const [{ data, fetching, error }, refetchFiles] = useQuery({
        variables: {
            siteUuid: siteId,
            anomalyUuid: anomalyUUID,
            superReportTaskUuid: inspectionId,
        },
        pause: !anomalyUUID || !inspectionId || !siteId,
        query: GET_ANOMALY_IMAGES_AND_FILES,
        requestPolicy: 'network-only',
    });

    const { internalFiles, externalFiles } = useMemo(() => {
        if (!fetching && !error && data) {
            return data.reedling_index_blob_instance_index.reduce(
                (acc: { internalFiles: BlobInstance[]; externalFiles: BlobInstance[] }, blobInstance: BlobInstance) => {
                    if (blobInstance.blob_index.is_internal) {
                        acc.internalFiles.push(blobInstance);
                    } else {
                        // if blob instance has a caption, decode it
                        if (blobInstance.caption) {
                            blobInstance.caption = decodeURIComponent(blobInstance.caption);
                        }

                        acc.externalFiles.push(blobInstance);
                    }

                    return acc;
                },
                { internalFiles: [], externalFiles: [] },
            );
        }

        return { internalFiles: [], externalFiles: [] };
    }, [fetching, error, data]);

    return { internalFiles, externalFiles, fetching, error, refetchFiles };
};

export default useAnomalyFiles;
