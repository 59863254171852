import { useState, useEffect } from 'react';

interface FeatureFlags {
    [key: string]: boolean;
}

const featureFlags: FeatureFlags = {
    IS_ANOMALY_FILE_UPLOAD_ENABLED: true,
    // Add more feature flags here
};

export const useFeatureFlag = (flagName: keyof typeof featureFlags) => {
    const [isEnabled, setIsEnabled] = useState(featureFlags[flagName]);

    useEffect(() => {
        setIsEnabled(featureFlags[flagName]);
    }, [flagName]);

    return isEnabled;
};
