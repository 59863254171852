import { ConfirmModal } from '@dronebase/shared-ui-core';
import { ModalProps, Text } from '@mantine/core';

import { LightThemeProvider } from 'providers/LightThemeProvider';

export interface DeleteModalProps extends ModalProps {
    opened: boolean;
    onClose: () => void;
    onDelete: () => void;
}

const DeleteModal = ({ opened, onClose, onDelete, ...props }: DeleteModalProps) => (
    <LightThemeProvider>
        <ConfirmModal
            {...props}
            opened={opened}
            onClose={onClose}
            title="Delete File"
            buttonPosition="end"
            confirmLabel="Delete"
            cancelLabel="Cancel"
            onConfirm={onDelete}
            size="sm"
        >
            <Text size="sm" variant="body2">
                The action can not be undone.
            </Text>
        </ConfirmModal>
    </LightThemeProvider>
);

export default DeleteModal;
