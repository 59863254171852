import { DefaultFile, MoreHor } from '@dronebase/shared-ui-icons';
import styled from '@emotion/styled';
import { Flex, ActionIcon, Image, Menu, Stack, Text, Loader, Progress, Center } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import ImageFile from 'components/common/Icons/ImageFile';
import OverflowTooltip from 'components/common/OverFlowTooltip';
import useDownloadImage from 'lib/hooks/useDownloadImage';
import { UploadState } from 'lib/hooks/useGCSUpload';
import { BlobInstance } from 'lib/types/inspections/anomalies';

export interface AnomalyFileCardProps {
    file?: BlobInstance;
    fileName?: string;
    isDeleteEnabled?: boolean;
    onRowClick?: () => void;
    onDelete?: (file: BlobInstance) => void;
    uploadState?: UploadState;
    caption?: string;
}

export default function AnomalyFileCard(props: AnomalyFileCardProps) {
    const { file, fileName, isDeleteEnabled, onRowClick, onDelete, uploadState, caption } = props;

    const download = useDownloadImage();
    const [menuOpen, { open: openMenu, close: closeMenu }] = useDisclosure(false);

    const { isUploading = false, progress = 0, caption: uploadCaption = '' } = uploadState || {};

    const fileIsImage = !!file?.blob_index?.type?.includes('image');

    const handleDelete = () => {
        if (file && onDelete) {
            onDelete(file);
        }
    };

    if (isUploading) {
        return (
            <HoverableFlex
                align="center"
                h="4rem"
                w="100%"
                columnGap={8}
                p="0.25rem"
                data-testid="uploading-anomaly-file-card"
            >
                <Flex w="20%" style={{ borderRadius: '0.25rem' }} justify="center">
                    <Center
                        h="3rem"
                        w="3rem"
                        style={{
                            borderRadius: '0.25rem',
                            backgroundColor: 'var(--color-grey-800)',
                        }}
                    >
                        <Loader size={24} />
                    </Center>
                </Flex>

                <Stack w="80%" h="100%" spacing={2} justify="center" px="0.5rem">
                    <OverflowTooltip
                        text={caption || uploadCaption || 'Uploading...'}
                        variant="body2light"
                        color="gray.0"
                    />
                    <Flex align="center" justify="space-between" w="100%">
                        <Progress
                            w="100%"
                            size="0.25rem"
                            value={progress}
                            styles={{
                                root: {
                                    backgroundColor: 'var(--color-grey-400)',
                                },
                                bar: {
                                    backgroundColor: 'var(--color-purple-300)',
                                },
                            }}
                        />
                        <Text variant="caption1" c="gray.2" maw="2rem" pr="0.5rem">
                            {`${progress}%`}
                        </Text>
                    </Flex>
                </Stack>
            </HoverableFlex>
        );
    }

    if (!file) {
        return null;
    }

    return (
        <HoverableFlex
            align="center"
            justify="space-between"
            h="3.5rem"
            w="100%"
            p="0.25rem"
            gap="0.75rem"
            onClick={onRowClick}
            data-testid="anomaly-file-card"
        >
            <Image
                withPlaceholder
                imageProps={{ defaultValue: '' }}
                placeholder={fileIsImage ? <ImageFile /> : <DefaultFile />}
                alt={fileName}
                src={file.url}
                width="3rem"
                height="3rem"
                fit="cover"
                radius="0.25rem"
            />

            <TextContainer justify="center" spacing="0.12rem">
                <OverflowTooltip text={file.caption || ''} variant="body2light" color="gray.0" />
                <OverflowTooltip text={fileName || ''} variant="caption1" color="gray.2" />
            </TextContainer>

            <Flex w="max-content" onClick={(e) => e.stopPropagation()}>
                <Menu
                    opened={menuOpen}
                    onOpen={openMenu}
                    onClose={closeMenu}
                    position="bottom-end"
                    width="10rem"
                    shadow="2px 2px 20px 0px #0000001A"
                >
                    <Menu.Target>
                        <ActionIcon p={0}>
                            <MoreHor />
                        </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown style={{ border: 0 }}>
                        <Menu.Item onClick={() => download(file.url)}>Download</Menu.Item>
                        {isDeleteEnabled && <Menu.Item onClick={handleDelete}>Delete</Menu.Item>}
                    </Menu.Dropdown>
                </Menu>
            </Flex>
        </HoverableFlex>
    );
}

const HoverableFlex = styled(Flex)({
    '&:hover': {
        backgroundColor: 'var(--color-grey-900)',
        borderRadius: '0.5rem',
        cursor: 'pointer',
    },
});

const TextContainer = styled(Stack)({
    flex: 1,
    minWidth: 0,
});
