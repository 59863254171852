// TODO - Move this to shared-ui-icons
const ImageFile = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.5 2.2C1.5 1.8134 1.8134 1.5 2.2 1.5H21.8C22.1866 1.5 22.5 1.8134 22.5 2.2V21.8C22.5 22.1866 22.1866 22.5 21.8 22.5H2.2C1.8134 22.5 1.5 22.1866 1.5 21.8V2.2ZM2.9 2.9V20.2907L15.9034 9.28785C16.1813 9.05269 16.5931 9.06982 16.8505 9.32725L21.1 13.5767V2.9H2.9ZM21.1 15.5566L16.316 10.7726L4.11096 21.1H21.1V15.5566ZM8.18889 7.25556C7.67342 7.25556 7.25555 7.67342 7.25555 8.18889C7.25555 8.70435 7.67342 9.12222 8.18889 9.12222C8.70436 9.12222 9.12222 8.70436 9.12222 8.18889C9.12222 7.67342 8.70436 7.25556 8.18889 7.25556ZM5.85556 8.18889C5.85556 6.90022 6.90023 5.85556 8.18889 5.85556C9.47755 5.85556 10.5222 6.90022 10.5222 8.18889C10.5222 9.
          47755 9.47755 10.5222 8.18889 10.5222C6.90023 10.5222 5.85556 9.47755 5.85556 8.18889Z"
            fill="#8D95A2"
        />
    </svg>
);

export default ImageFile;
