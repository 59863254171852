import { notifications } from '@dronebase/shared-ui-core';

import { Config } from 'config';
import { fetchData } from 'lib/hooks';

const deleteAnomalyFile = async (anomalyUUID: string) => {
    const { data, error } = await fetchData({
        url: `${Config.get('REEDLING_BLOB_MANAGEMENT_URL')}/blobs/${anomalyUUID}/delete`,
        method: 'POST',
        body: {
            in_all_workspaces: false,
        },
        externalLink: false,
    });

    if (error) {
        notifications.error({
            title: 'Error deleting file',
            withCloseButton: true,
        });
    } else {
        notifications.success({
            title: 'File deleted successfully',
            withCloseButton: true,
        });
    }

    return { data, error };
};

export default deleteAnomalyFile;
