import { useState, useEffect } from 'react';

import exifr from 'exifr';

interface UseGpsFromImageReturn {
    latitude: number | null;
    longitude: number | null;
    loading: boolean;
    error: string | null;
}

export const useGpsFromImage = (file: File | Blob | null): UseGpsFromImageReturn => {
    const [latitude, setLatitude] = useState<number | null>(null);
    const [longitude, setLongitude] = useState<number | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        let isCancelled = false;

        const parseImage = async () => {
            if (!file) {
                setLatitude(null);
                setLongitude(null);
                setError(null);

                return;
            }

            setLoading(true);
            setError(null);

            try {
                const metadata = await exifr.parse(file, { xmp: true, iptc: true });

                if (!isCancelled) {
                    if (
                        metadata &&
                        metadata.latitude &&
                        metadata.longitude &&
                        typeof metadata.latitude === 'number' &&
                        typeof metadata.longitude === 'number'
                    ) {
                        setLatitude(metadata.latitude);
                        setLongitude(metadata.longitude);
                    } else {
                        setError('No GPS data found in this image.');
                    }
                }
            } catch (err: unknown) {
                if (!isCancelled) {
                    if (err instanceof Error) {
                        setError(`Error reading EXIF data: ${err.message}`);
                    } else {
                        setError(`Error reading EXIF data: ${String(err)}`);
                    }
                }
            } finally {
                if (!isCancelled) {
                    setLoading(false);
                }
            }
        };

        parseImage();

        return () => {
            isCancelled = true;
        };
    }, [file]);

    return { latitude, longitude, loading, error };
};
