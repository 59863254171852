import { useState } from 'react';

export interface Metadata {
    url: string; // GCS signed URL
    headers: { [key: string]: string }; // x-goog-meta headers
}

export interface UploadState {
    progress: number;
    isUploading: boolean;
    error: string | null;
    isCompleted: boolean;
    caption: string;
}

export const useGcsUpload = () => {
    const [uploadState, setUploadState] = useState<UploadState>({
        progress: 0,
        isUploading: false,
        error: null,
        isCompleted: false,
        caption: '',
    });

    /**
     * Let external code finalize the upload (set isUploading=false)
     * after any desired delay or refetch.
     */
    const finalizeUpload = () => {
        setUploadState((prev) => ({
            ...prev,
            isUploading: false,
        }));
    };

    const uploadToGCS = async (file: File, metadata: Metadata): Promise<{ ok: boolean }> => {
        if (!file || !metadata || !metadata.url) {
            const errorMsg = 'File and metadata with URL are required.';

            setUploadState((prev) => ({ ...prev, error: errorMsg }));

            throw new Error(errorMsg);
        }

        try {
            const fileName = file.name;
            const caption = decodeURIComponent(metadata.headers['x-goog-meta-caption']) || fileName;

            setUploadState((prev) => ({
                ...prev,
                isUploading: true,
                progress: 0,
                error: null,
                caption,
                isCompleted: false,
            }));

            const xhr = new XMLHttpRequest();

            xhr.open('PUT', metadata.url);

            const signedHeaders = new URL(metadata.url).searchParams.get('X-Goog-SignedHeaders');

            if (!signedHeaders) {
                throw new Error('Signed headers are missing in the metadata URL.');
            }

            const requiredHeaders = signedHeaders.split(';');

            xhr.setRequestHeader('Content-Type', file.type);

            requiredHeaders.forEach((header) => {
                if (header.startsWith('x-goog-meta-')) {
                    if (!metadata.headers[header]) {
                        throw new Error(`Missing required header: ${header}.`);
                    }

                    xhr.setRequestHeader(header, metadata.headers[header]);
                }
            });

            return new Promise((resolve, reject) => {
                xhr.upload.onprogress = (event) => {
                    if (event.lengthComputable) {
                        const progress = Math.round((event.loaded / event.total) * 100);

                        setUploadState((prev) => ({ ...prev, progress }));
                    }
                };

                xhr.onload = () => {
                    if (xhr.status >= 200 && xhr.status < 300) {
                        setUploadState((prev) => ({
                            ...prev,
                            progress: 100,
                            error: null,
                            isCompleted: true,
                        }));
                        resolve({ ok: true });
                    } else {
                        const errorMsg = `Upload failed with status ${xhr.status}: ${xhr.responseText}`;

                        setUploadState({
                            progress: 0,
                            isUploading: false,
                            error: errorMsg,
                            isCompleted: false,
                            caption: '',
                        });
                        reject(new Error(errorMsg));
                    }
                };

                xhr.onerror = () => {
                    const errorMsg = `Network error during upload of file: ${file.name}`;

                    setUploadState({
                        progress: 0,
                        isUploading: false,
                        error: errorMsg,
                        isCompleted: false,
                        caption: '',
                    });
                    reject(new Error(errorMsg));
                };

                xhr.send(file);
            });
        } catch (error) {
            setUploadState((prev) => ({
                ...prev,
                error: (error as Error).message,
                isUploading: false,
                isCompleted: false,
            }));

            throw error;
        }
    };

    return {
        uploadToGCS,
        uploadState,
        finalizeUpload,
    };
};
